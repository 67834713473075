import React from 'react';
import './seo.css';
import categoriesData from './categories.seo.json';
import {ISeoDictionaries} from './seo.models';

const Seo: React.FC = () => {
  
  const data: ISeoDictionaries = categoriesData;

  return (
    <div className="seoSection">
      <div className='seoContent'>
        <div className="seoGroup">
          <h2 className="sectionTitle">Polskie marki odzieżowe w jednym miejscu</h2>
          <div className="description-text">Kupuj lokalnie. Wspieraj polskich producentów ubrań.</div>
          <div className="description-text">
            <b>Polskie marki odzieżowe</b> zyskują na popularności. Konsumenci coraz częściej zwracają uwagę na proces produkcyjny ubrania. Polskie ubrania są coraz chętnie poszukiwane na rynku, a rynek polskich firm odzieżowych zyskuje na popularności.
          </div>
          <div className="description-text">Ubrania <b>wyprodukowane w Polsce</b> kojarzą się z dobrą jakością i wspieraniem lokalności. Ze względu na krótszy łańcuch dostaw, doceniany jest też mniejszy wpływ produkcji ubrań na środowisko.</div>
          <div className="description-text">Dużą zaletą <b>polskich marek odzieżowych</b> jest większa transparentność procesu produkcyjnego. Tym co wyróżnia polskie marki odzieżowe jest także możliwość spotkania się z producentami osobiście na różnego rodzaju wydarzeniach, targach co w przypadku sieciówek jest wręcz niemożliwe.</div> 
        </div> 
        <div className="seoGroup" data-test="gender">
          <h2 className='title'> Polskie Ubrania Dla Ciebie i Twoich Bliskich</h2>
          {data.genders.map((category) => (
          <div className="option">
            <div className="option-title" ><a href={`/filtry/${category.code};g`} ><b>{category.title}</b></a></div>
            <div className="description">{category.description} </div>                
          </div>
          ))}
        </div>        
        <div className="seoGroup" data-test="clothesType">  
          <h2 className='title'>Filtruj polskie ubrania według typu</h2>
            <div className='typical'>Przeglądaj firmy produkujące polskie ubrania. Filtruj polskie firmy mające w swojej ofercie różne typy ubrań w tym dresy, bluzki, kurtki i wiele innych.</div>
            <div className='clothes-options'>
            {data.clothesTypes.map((category, index) => (
              <div className="item" key={category.name}>
                <a 
                  href={`/filtry/${category.code};tc`} 
                  className="option-title" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  aria-label={category.ariaLabel}
                >
                  {category.name}
                </a>    
                {index < data.clothesTypes.length - 1 ? 
                  <span className="separator"> • </span> : 
                  <span className="separator">&nbsp;</span>}
              </div>
            ))}
            </div>
          </div>
      </div>
    </div>
  );
};

export default Seo;
