import './regulations.css';

const Regulations = () => {
    return (
        <div className="container-policy">
            <h1>Regulamin Strony Internetowej</h1>
            <h2>1. Postanowienia ogólne</h2>
            <p>
                1.1. Niniejszy regulamin (dalej „Regulamin”) określa zasady korzystania ze strony internetowej „Szafa Polska” (dalej „Strona”).
            </p>
            <p>
                1.2. Strona jest platformą, która umożliwia użytkownikom przeglądanie listy polskich firm zajmujących się produkcją ubrań.
            </p>
            <p>
                1.3. Właścicielem Strony jest osoba prywatna.
            </p>
            <h2>2. Warunki korzystania ze Strony</h2>
            <p>
                2.1. Korzystając z Strony, użytkownik zobowiązuje się do przestrzegania postanowień Regulaminu oraz przepisów prawa.
            </p>
            <p>
                2.2. Użytkownik ma prawo do korzystania ze Strony wyłącznie w celach osobistych i niekomercyjnych.
            </p>
            <p>
                2.3. Użytkownik zobowiązuje się do niepodejmowania działań mogących zakłócić funkcjonowanie Strony.
            </p>
            <h2>3. Dodawanie firm</h2>
            <p>
                3.1. Możliwość dodawania firm do Strony przysługuje wyłącznie właścicielowi Strony.
            </p>
            <p>
                3.2. Użytkownicy mogą zgłaszać propozycje firm, które powinny zostać dodane, poprzez formularz kontaktowy dostępny na Stronie.
            </p>
            <p>
                3.3. Użytkownicy mogą zgłaszać potrzebę aktualizacji informacji o firmie, produktów przez nią ofertowanych, poprzez formularz kontaktowy dostępny na Stronie.
            </p>
            <p>
                3.4. Użytkownicy będący właścicielami firmy mogą zgłaszać prośbę usunięcia firmy z katalogu, poprzez formularz kontaktowy dostępny na Stronie.
            </p>
            <h2>4. Odpowiedzialność</h2>
            <p>
                4.1. Właściciel Strony nie ponosi odpowiedzialności za treści zamieszczone przez użytkowników ani za działanie zewnętrznych stron internetowych, do których mogą prowadzić linki znajdujące się na Stronie.
            </p>
            <p>
                4.2. Wszelkie informacje zawarte na Stronie mają charakter wyłącznie informacyjny i nie stanowią rekomendacji ani porady zakupowej. Użytkownicy podejmują decyzje zakupowe na własną odpowiedzialność.
            </p>
            <p>
                4.3. Właściciel Strony nie ponosi odpowiedzialności za jakiekolwiek szkody wynikające z korzystania z informacji zawartych na Stronie.
            </p>
            <h2>5. Ochrona danych osobowych</h2>
            <p>
                5.1. Przetwarzanie danych osobowych użytkowników odbywa się zgodnie z obowiązującymi przepisami prawa oraz Polityką Prywatności dostępną na Stronie.
            </p>
            <h2>6. Postanowienia końcowe</h2>
            <p>
                6.1. Regulamin wchodzi w życie z dniem jego opublikowania na Stronie.
            </p>
            <p>
                6.2. Właściciel Strony zastrzega sobie prawo do zmiany Regulaminu w każdym czasie. Zmiany wchodzą w życie z chwilą ich opublikowania na Stronie.
            </p>
        </div>
    );
};

export default Regulations;
