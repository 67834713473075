import React from 'react';
import { IStore } from '../../types/IStore';
import './StoreCard.css';

interface StoreCardProps {
    store: IStore;
}

const StoreCard: React.FC<StoreCardProps> = ({ store }) => {
    return (
        <div className="store-card" aria-label={`polski sklep ${store.name} z ubraniami dla: ${store.genders.join(', ')}`}>
            <h2 className="store-name">{store.name}</h2>
            <div className="store">
                <div className="store-info">
                    <span className="info-label">Rodzaje ubrań:</span>
                    <span className="info-value">{store.clothesTypes.join(', ')}</span>
                </div>
                <div className="store-info">
                    <span className="info-label">Dla kogo:</span>
                    <span className="info-value">{store.genders.join(', ')}</span>
                </div>  
            </div>
            
            <div className='tags-container'>
                    {store.tags.map((tag) => (
                        <button
                            key={tag}
                            className={`tag-chip`}
                        >
                            #{tag}
                        </button>
                    ))}
                </div>
            <div className="store-button-container">
                {store.urls.length > 0 && (
                    <a href={store.urls[0]} className="store-button" target="_blank" rel="noopener noreferrer">
                        Przejdź do sklepu
                    </a>
                )}
            </div>
        </div>
    );
};

export default StoreCard;
