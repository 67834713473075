import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import {contact,storesListing} from './../dictionaries/navigation';

const NavBar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLUListElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)&& buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">
          <img src="/logos.png" alt="Logo Szafa Polska" className="logo-desktop"/>
          <img src="/logo_base.png" alt="Logo Szafa Polska" className="logo-mobile"/>
        </Link>
      </div>
      <button className="navbar-toggle" onClick={toggleMenu} ref={buttonRef}>
        &#9776;
      </button>
      <ul className={`navbar-menu ${menuOpen ? 'active' : ''}`} ref={menuRef}>
        <li><Link to={storesListing} onClick={toggleMenu}>Sklepy</Link></li>
        <li><Link to={contact} onClick={toggleMenu}>Kontakt</Link></li>
      </ul>
    </nav>
  );
};

export default NavBar;
