import './regulations.css';

const PrivacyPolicy = () => {
    return (
        <div className="container-policy">
            <h1>Polityka Prywatności</h1>
            <h2>1. Wstęp</h2>
            <p>
                1.1. Polityka Prywatności wyjaśnia, w jaki sposób zbieramy, przechowujemy, przetwarzamy i chronimy dane osobowe użytkowników Strony „Szafa Polska”.
            </p>
            <p>
                1.2. Właścicielem i administratorem Strony jest osoba prywatna, z którą można skontaktować się przez formularz kontaktowy dostępny na stronie lub pod adresem e-mail: kontakt@szafapolska.pl.
            </p>
            <h2>2. Zbieranie danych osobowych</h2>
            <p>
                2.1. Zbieramy dane osobowe użytkowników wyłącznie w celu realizacji funkcji dostępnych na Stronie, w tym poprzez formularz kontaktowy.
            </p>
            <p>
                2.2. Dane, które możemy zbierać, to:
                <ul>
                    <li>Imię i nazwisko,</li>
                    <li>Adres e-mail,</li>
                    <li>Numer telefonu (opcjonalnie).</li>
                </ul>
            </p>
            <h2>3. Przetwarzanie danych osobowych</h2>
            <p>
                3.1. Przetwarzamy dane osobowe w celu:
                <ul>
                    <li>Odpowiedzi na zapytania użytkowników,</li>
                    <li>Realizacji funkcji Strony.</li>
                </ul>
            </p>
            <p>
                3.2. Użytkownik ma prawo do dostępu do swoich danych osobowych, ich poprawiania, usunięcia oraz ograniczenia przetwarzania.
            </p>
            <h2>4. Ochrona danych osobowych</h2>
            <p>
                4.1. Dokładamy wszelkich starań, aby zapewnić bezpieczeństwo danych osobowych użytkowników, w tym stosując odpowiednie środki techniczne i organizacyjne.
            </p>
            <h2>5. Udostępnianie danych osobowych</h2>
            <p>
                5.1. Nie sprzedajemy ani nie udostępniamy danych osobowych użytkowników osobom trzecim bez ich zgody, chyba że wymaga tego prawo.
            </p>
            <h2>6. Zmiany w Polityce Prywatności</h2>
            <p>
                6.1. Zastrzegamy sobie prawo do wprowadzania zmian w Polityce Prywatności. Zmiany wchodzą w życie z chwilą ich opublikowania na Stronie.
            </p>
            <h2>7. Pliki cookie</h2>
            <p>
                7.1. Strona nie wykorzystuje plików cookie do zbierania danych osobowych użytkowników.
            </p>
            <p>
                7.2. Użytkownicy są informowani, że korzystanie z niektórych funkcji Strony może wiązać się z używaniem plików cookie przez zewnętrzne usługi, ale nie są one wykorzystywane do śledzenia danych osobowych.
            </p>
            <h2>8. Zewnętrzne skrypty</h2>
            <p>
                8.1. Nasza strona może korzystać z zewnętrznych skryptów i usług, takich jak Google Analytics, które pomagają nam w analizie ruchu na stronie. Informacje zebrane przez te skrypty mogą zawierać dane osobowe.
            </p>
            <p>
                8.2. Użytkownik ma możliwość zapoznania się z polityką prywatności tych usług i sposobem przetwarzania danych, które mogą być przez nie zbierane.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
