// src/components/Loader.tsx
import React from 'react';
import './loader.css'; // Stwórz plik CSS dla stylizacji loadera

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
      <p>Ładowanie...</p>
    </div>
  );
};

export default Loader;
