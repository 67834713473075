import React from 'react';
import './home.css';
import { storesListing } from '../../dictionaries/navigation';
import Hero from './hero';

export const Home: React.FC = () => {
    return (
        <div>
            <div className='hero'>
                <h1>Polskie marki w jednym miejscu!</h1>
            </div>

            <div className="intro">
                <div className="intro-container">
                    <div className="image-container">
                        <img src="/path/to/image.jpg" alt="Etyczna moda" className="fashion-image" />
                    </div>
                    <div className="text-container">
                        <h1 className="intro-title"></h1>
                        
                        <p className="intro-description">
                            Nasza aplikacja to miejsce, w którym szybko i łatwo znajdziesz polskie marki odzieżowe oferujące etycznie produkowane ubrania wysokiej jakości. 
                            Dzięki nam zaoszczędzisz czas i zminimalizujesz stres związany z przeszukiwaniem internetu. 
                            W jednym miejscu zgromadziliśmy firmy, które dbają o jakość, zrównoważony rozwój i… Ciebie!
                        </p>
                        
                        <p className="intro-description">
                            Z nami podejmiesz świadome decyzje, wspierając lokalnych producentów i wybierając modę, która ma znaczenie. 
                            Odkryj najlepsze polskie marki i ciesz się zakupami, które są zgodne z Twoimi wartościami!
                        </p>
                    </div>
                </div> 
                <div className=''>
                    <button className='show'><a href={storesListing}> Poznaj polskie marki</a></button>
                </div>
            </div>
           
        </div>
    );
};
